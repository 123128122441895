import { Card, CardBody, Text, HStack, Image, CloseButton } from "@chakra-ui/react"
import { getLabel } from "./labels"
import { minimalDataPerLabel } from "./minimalDataPerLabel"
import { CloseIcon } from "@chakra-ui/icons";

interface Props {
    item: any;
    isSelected?: boolean;
    onToggleSelect?: (id: string) => void;
    hideImage?: boolean;
    onClickOnRemove?: () => void;
    isSmall?: boolean;
}

export const TablePageDocument = ({ item, isSelected, onToggleSelect, hideImage, onClickOnRemove, isSmall }: Props) => {
    return (<Card borderWidth={1} borderColor={isSelected ? 'blue.500' : 'gray.200'} backgroundColor={isSelected ? 'blue.200' : 'white'} variant={'outlined'} onClick={() => onToggleSelect?.(item.id)}>
        <CardBody py={isSmall ? 1 : 2} px={2}>
            <HStack spacing='2' justifyContent={"space-between"}>
                {onClickOnRemove ? <CloseIcon cursor={'pointer'} fontSize={'.6rem'} onClick={(e) => {onClickOnRemove() }}></CloseIcon> : null}
                <Text fontSize={'xs'} as="strong">{getLabel(item.label)}</Text>
                {!isSmall ? <Text fontSize={'xs'}>{item.data?.find((itemData: any) => itemData.id === minimalDataPerLabel[item.label]?.[0])?.value}</Text> : null}
            </HStack>
            {isSmall ? <Text ms={'18px'} fontSize={'xs'}>{item.data?.find((itemData: any) => itemData.id === minimalDataPerLabel[item.label]?.[0])?.value}</Text> : null}
            {!hideImage ? <Image marginTop={2} height={'100px'} width={'100%'} rounded={2} objectFit={'cover'} src={`https://cokhhrrfza.cloudimg.io/_img_/${item.processedFileKey}?width=80}`}></Image> : null}
        </CardBody>
    </Card>)
}
import { useState, useEffect } from 'react';

const useSessionStorageState = <T>(key: string, initialValue: T): [state: T, setState: React.Dispatch<React.SetStateAction<T>>] => {
    const [state, setState] = useState<T>(() => {
        const storedValue = sessionStorage.getItem(key);
        return storedValue ? JSON.parse(storedValue) : initialValue;
    });

    useEffect(() => {
        sessionStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);

    return [state, setState];
};

export default useSessionStorageState;

export const useReadOnlySessionStorageState = <T>(key: string): T => {
    const storedValue = sessionStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : undefined;
};

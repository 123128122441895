export const DocumentTypeLabels: Record<string, string> = {
    'idCardBack': 'DNI (dorso)',
    'idCardFront': 'DNI (frente)',
    'idCardComplete': 'DNI (completo)',
    'idCard': 'DNI',
    'circulationPermitV2Document': 'Permiso C.',
    'circulationPermitBackV2Document': 'Permiso C.',
    'circulationPermitV1Document': 'Permiso C.',
    'circulationPermitBackV1Document': 'Permiso C. (dorso)',
    'contract': 'Contrato',
    'mandate': 'Mandato',
    'vehicleTechnicalDocumentV2': 'Ficha técnica',
    'vehicleTechnicalDocumentV1': 'Ficha técnica',
    'unknown': "Desconocido",
}

export const IdCardLabels: Record<string, string> = {
    address: 'Domicilio',
    birthDate: 'Fecha de nacimiento',
    documentNumber: 'Número de documento',
    expirationDate: 'Fecha de vencimiento',
    name: 'Nombre',
    firstSurname: 'Primer apellido',
    secondSurname: 'Segundo apellido',
    gender: 'Género',
}

export const MandateLabels: Record<string, string> = {
    mandatarioID: 'DNI del mandatario',
    mandatarioName: 'Nombre del mandatario',
    mandatarioAddress: 'Domicilio del mandatario',
    mandanteID: 'DNI del mandante',
    mandanteName: 'Nombre del mandante',
    mandanteAddress: 'Domicilio del mandante',
}

export const ContractLabels: Record<string, string> = {
    sellerName: 'Nombre del vendedor',
    sellerFullName: 'Vendedor',
    buyerFullName: 'Comprador',
    sellerDocumentNumber: 'DNI del vendedor',
    sellerFirstSurname: 'Primer apellido del vendedor',
    sellerSecondSurname: 'Segundo apellido del vendedor',
    buyerName: 'Nombre del comprador',
    buyerDocumentNumber: 'DNI del comprador',
    buyerFirstSurname: 'Primer apellido del comprador',
    buyerSecondSurname: 'Segundo apellido del comprador',
    vehicleBrand: 'Marca del vehículo',
    vehicleModel: 'Modelo del vehículo',
    vehicleKms: 'Kilómetros del vehículo',
    buyerCompany: 'Empresa compradora',
    sellerCompany: 'Empresa vendedora',
    buyerCompanyId: 'CIF de la empresa compradora',
    sellerCompanyId: 'CIF de la empresa vendedora',
    vehiclePrice: 'Precio de compraventa',
}

export const GroupLabels: Record<string, string> = {
    'idNumber': 'DNI',
}

export const getLabel = (label: string) => {
    return IdCardLabels[label] || MandateLabels[label] || ContractLabels[label] || DocumentTypeLabels[label] || GroupLabels[label] || label;
}
import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Button,
    Heading,
    useColorModeValue,
    useToast,
  } from '@chakra-ui/react'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { AuthService } from './AuthService';
import { useNavigate } from 'react-router-dom';
import { ApiService } from './ApiService';
  
  export default function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const toast = useToast();
    const navigate = useNavigate();

    const {mutate: login} = useMutation((data: {email: string, password: string}) => {
      return ApiService.login({email: data.email, password: data.password}).then(async res => {
        switch(res.status) {
          case 401: toast({
              title: "Usuario o contraseña incorrectos",
              status: "error",
              duration: 9000,
              isClosable: true,
          }); break;
            case 201:
              //If sucessfully logged in, we store username in local storage
              const token = (await res.json()).access_token;
              AuthService.onLogin(token);
              setTimeout(() => navigate('/'), 1000);
              break;
        }
      })
    })

    return (
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}>
        <Stack spacing={8} mx={'auto'} minW={'sm'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'}>Wheelz</Heading>
          </Stack>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <Stack spacing={4}>
              <FormControl id="email">
                <FormLabel>Email</FormLabel>
                <Input value={email} onChange={(e) => setEmail(e.target.value)} type="email" />
              </FormControl>
              <FormControl id="password">
                <FormLabel>Contraseña</FormLabel>
                <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
              </FormControl>
              <Stack spacing={10}>
                <Button
                  
                  onClick={() => login({email, password})}
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}>
                  Acceder
                </Button>
              </Stack>
              <Stack spacing={10}>
                  <Button
                      variant={'outline'}
                      onClick={() => navigate('/signup')}
                      colorScheme={'blue'}
                      _hover={{
                          bg: 'blue.500',
                      }}>
                      Registrarme
                    </Button>
                </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    )
  }
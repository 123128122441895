import { ArrowUpIcon } from "@chakra-ui/icons"
import { Box, Button, Heading, List, ListIcon, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Tag, Text, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { DropZone } from "./DropZone";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import { ApiService } from "./ApiService";
import { useNavigate } from 'react-router-dom';
import { AuthService } from "./AuthService";

interface Props {
    documentSelection: string[];
    onClearDocumentSelection: () => void;
    setIsShowingProcessed: (isShowingProcessed: boolean) => void;
    isShowingProcessed: boolean;
}

export const Header = ({
    documentSelection,
    onClearDocumentSelection,
    setIsShowingProcessed,
    isShowingProcessed
}: Props) => {

    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
    const toast = useToast()

    const batchFileUpload = useMutation(async (files: File[]) => {
        // If there are no files selected
        if (!files.length) {
            toast({
                title: "No se ha seleccionado ningún archivo",
                status: "warning",
                duration: 9000,
                isClosable: true,
            })
            return null;
        }

        const formData = new FormData();
        files.forEach(file => {
            formData.append('files', file);
        })
        return ApiService.uploadFiles(formData);
    });

    const handleUpload = async () => {
        try {
            await batchFileUpload.mutateAsync(acceptedFiles)
                .then(async (res) => {
                    // User did not select any file
                    if (res === null) return;
                    if (res.status == 403) {
                        toast({
                            title: (await res.json()).message,
                            status: "warning",
                            duration: 9000,
                            isClosable: true,
                        })
                        return;
                    }

                    toast({
                        title: "Documentos subidos",
                        description: "Los documentos han sido subidos correctamente",
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                    })

                })
                .finally(() => {
                    setUploadModalOpen(false);
                    setAcceptedFiles([]);
                });
        } catch (error) {
            toast({
                title: "Error al subir documentos",
                description: "Ha ocurrido un error al subir los documentos",
                status: "error",
                duration: 9000,
                isClosable: true,
            })
        }
    }

    const navigate = useNavigate();

    return (
        <>
            <Box backgroundColor={'gray.200'} height={'60px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} px={5}>
                <Heading color={'gray.700'} as='h1' size='md' noOfLines={1}>
                    Wheelz [BETA]
                </Heading>
                <Box display={'flex'}>
                    <Button onClick={() => setIsShowingProcessed(false)} size="sm" me={2}  variant={isShowingProcessed ? 'outline' : 'solid'}>Por tramitar</Button>
                    <Button onClick={() => setIsShowingProcessed(true)} size="sm" me={2}  variant={!isShowingProcessed ? 'outline' : 'solid'}>Tramitados</Button>
                    <EmailButton></EmailButton>
                    <LogoutButton></LogoutButton>
                    <Button me={2} onClick={() => setUploadModalOpen(true)} size="sm" colorScheme='gray.200' variant='outline'><ArrowUpIcon marginRight={'10px'} /> Subir documentos</Button>
                    <Button onClick={() => onClearDocumentSelection?.()} size="sm" me={2} colorScheme='blue.200' variant='outline'>Borrar selección</Button>
                    <Button disabled={!documentSelection.length} onClick={() => documentSelection.length && navigate('/tramitar')} size="sm" colorScheme={'blue.200'} variant='outline'>Tramitar selección ({documentSelection.length})</Button>
                </Box>
            </Box>
            <Modal onClose={() => setUploadModalOpen(false)} size={'xl'} isOpen={uploadModalOpen}>
                <ModalOverlay />
                <ModalContent p={5}>
                    <ModalHeader>Subir documentos</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text mb={5}>Arrastra aquí todos los documentos para el trámite. Para A9 sólo necesitamos <strong>Permiso de Circulación</strong> y <strong>DNIs</strong>, pero puedes subir los que necesites para exportarlos en PDF. Soportamos <Tag>JPEG</Tag> y <Tag>PDF</Tag></Text>
                        <Text>Recomendamos no subir más de 8 documentos a la vez</Text>
                        <DropZone onAcceptedFilesChange={(files) => setAcceptedFiles(files)}></DropZone>
                        <List mt={5} spacing={2}>
                            {acceptedFiles.map((file, idx) => (
                                <ListItem key={idx}>
                                    <ListIcon as={ArrowUpIcon} color='gray.500' />
                                    {file.name}
                                </ListItem>
                            ))}
                        </List>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={batchFileUpload.isLoading} onClick={() => setUploadModalOpen(false)}>Cerrar</Button>
                        <Button disabled={batchFileUpload.isLoading} colorScheme="blue" ms={3} onClick={() => !batchFileUpload.isLoading && handleUpload()}>Subir</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

const LogoutButton = () => {
    const navigate = useNavigate();

    const logout = () => {
        AuthService.logout();
        navigate("/login");
    };

    return (
        <Button me={4} size="sm" colorScheme="red" variant='ghost' onClick={logout}>
            Cerrar Sesión
        </Button>
    );
};


const EmailButton = () => {
    const [email, setEmail] = useState('');

    useEffect(() => {
        (async () => {
            try {
                const response = await ApiService.email();
                const data = await response.json();
                setEmail(data.email);
            } catch (error) {
                console.error('Failed to fetch email:', error);
            }
        })()
    }, []);

    // This Button should be replaced with Text component
    return (
        <Text fontSize={'sm'} display={'flex'} alignItems={'center'} mr={4}>
            {email}
        </Text>
    );
};
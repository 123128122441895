import { ChakraProvider } from '@chakra-ui/react'
import { DocumentPage } from './DocumentPage'
import { queryClient } from './queryClient'
import { QueryClientProvider } from 'react-query'
import { TablePage } from './TablePage'
import { HashRouter, Route, Routes } from 'react-router-dom'
import Login from './Login'
import SignUp from './SignUp'

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider>
        <HashRouter>
          <Routes>
            <Route path="/" element={<TablePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/tramitar" element={<DocumentPage />} />
            <Route path="/signup" element={<SignUp />} />
          </Routes>
        </HashRouter>
      </ChakraProvider>
    </QueryClientProvider>
  )
}

import { Box, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

export interface Props {
  onAcceptedFilesChange: (files: File[]) => void;
}

export const DropZone = (props: Props) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  useEffect(() => {
    props.onAcceptedFilesChange(acceptedFiles);
  }, [acceptedFiles]);

  return (
    <Box {...getRootProps({ className: 'dropzone' })} display={'flex'} justifyContent={'center'} alignItems={"center"} mt={4} h={'100px'} borderColor={'gray.500'} borderWidth={'2px'} borderStyle={'dashed'} borderRadius={'10px'}>
      <input {...getInputProps()} />
      <Text color={'gray.500'}>Arrastra aquí o haz click para subir PDFs</Text>
    </Box>
  );
}


import { Box, Button, CloseButton, FormControl, FormHelperText, FormLabel, HStack, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spinner, Switch, Text, VStack, useToast } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { DocumentTypeLabels } from "./labels";
import { Link } from "react-router-dom";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import useSessionStorageState, { useReadOnlySessionStorageState } from "./useSessionStorageState";
import { ApiService } from "./ApiService";
import { DocumentInput } from "./DocumentInput";
import { minimalDataPerLabel } from "./minimalDataPerLabel";
import { HelpTooltip } from "./HelpTooltip";

const helpTextPdf = "Exporta los documentos en un único PDF. Puedes cambiar el orden con las flechas en cada documento"
const helpTextDeleteBackground = "Elimina el fondo en las imágenes al exportar en PDF. Utilizalo sólo con imágenes donde el documento no esté muy pegado a los bordes para obtener buenos resultados."
const helpTextSave = "Guarda los cambios realizados en los documentos";

export const DocumentPage = () => {

    const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(null);
    const [view, setView] = useState<'documents' | 'validation'>('documents');
    const [_, setDocumentIds] = useSessionStorageState<string[]>('selection', []);
    const documentIds = useReadOnlySessionStorageState<string[]>('selection');
    const [sortedDocuments, setSortedDocuments] = useState<any[]>([]);
    const [XMLModalOpen, setXMLModalOpen] = useState(false);
    const [documentsFormData, setDocumentsFormData] = useState<any[]>([]);
    const [buyerDocumentKey, setBuyerDocumentKey] = useState<string>();
    const [sellerDocumentKey, setSellerDocumentKey] = useState<string>();
    const [vehicleDocumentKey, setVehicleDocumentKey] = useState<string>();
    const [removeBackgroundDocumentIds, setRemoveBackgroundDocumentIds] = useState<string[]>([]);

    const canGenerateXML = useMemo(() => {
        return !!buyerDocumentKey && !!sellerDocumentKey && !!vehicleDocumentKey;
    }, [vehicleDocumentKey, buyerDocumentKey, sellerDocumentKey]);

    const { data: documents, refetch: refetchDocuments } = useQuery(['documents', documentIds], () => {
        console.log('fetching documents');
        return ApiService.getSomeDocuments(documentIds || []).then(res => res.json());
    }, {enabled: false})

    const toast = useToast();

    useEffect(() => {
        console.log('refetching documents');
        refetchDocuments();
    }, []);

    const defaultFormData = useMemo(() => sortedDocuments?.map((doc: any) => ({
        documentKey: doc.documentKey,
        data: doc.data?.reduce((acc: any, item: any) => {
            acc[item.id] = item.value;
            return acc;
        }, {})
    })), [sortedDocuments]);


    useEffect(() => {
        if(documentsFormData.length){
            return;
        }
        setDocumentsFormData(defaultFormData);
    }, [defaultFormData]);

    const { mutate: mutatePersistDocuments } = useMutation('persist-documents', (): any => {
        return ApiService.persistDocumentsUserData(documentsFormData).then(res => toast({
            title: "Documentos guardados",
            description: "Los datos de los documentos se han guardado correctamente",
            status: "success",
            duration: 9000,
            isClosable: true,
        }));
    });

    const { mutate: generateXML } = useMutation('generate-xml', async (): Promise<void> => {
        return ApiService.generateA9XML({ documentKeys: documentsFormData?.map((doc) => doc.documentKey), buyerDocumentKey, sellerDocumentKey }).then(res => {
            res.blob().then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `a9.xml`,
                );
                document.body.appendChild(link);
                link.click();
                link?.parentNode?.removeChild(link);
            });
        });
    });

    const { mutate: exportPDF, isLoading: exportPDFIsLoading } = useMutation('expoert-pdf', async (): Promise<void> => {
        return ApiService.exportPDF(sortedDocuments?.map((doc: any) => ({ file_key: doc.processedFileKey, removeBackground: !!removeBackgroundDocumentIds.find(id => doc.id === id) }))).then(mergedFileKey => {
            const url = `https://idp-processed-merged.s3.eu-west-3.amazonaws.com/${mergedFileKey}`
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `export.pdf`,
            );
            link.setAttribute(
                'target',
                `_blank`,
            );
            document.body.appendChild(link);
            link.click();
            link?.parentNode?.removeChild(link);
        });
    });

    useEffect(() => {
        if (!documents) return;
        setSortedDocuments(documents);
    }, [documents]);

    const onClickOnGenerateXML = async () => {
        if (!canGenerateXML) return;
        setXMLModalOpen(false);
        await mutatePersistDocuments();
        await generateXML();
    }

    useEffect(() => {
        const firstVehicleCirculationPermit = documents?.find((d: any) => d.label === 'circulationPermitV2Document');
        setVehicleDocumentKey(firstVehicleCirculationPermit?.documentKey);
    }, [documents])

    const selectedVehicleCirculationPermit = documents?.find((d: any) => d.documentKey === vehicleDocumentKey);

    const handleFormDataChange = (documentId: string | null, dataId: string, value: string) => {
        const documentKey = sortedDocuments.find((d) => d.id === documentId)?.documentKey;
        if(!documentKey) return;
        setDocumentsFormData((current) => current.map((doc) => {
            if (doc.documentKey === documentKey) {
                doc.data[dataId] = value;
            }
            return doc;
        }));
    }

    const onClickOnRemoveDocument = (id: string) => {
        setDocumentIds((current) => {
            return current.filter((item) => item !== id);
        });
        setSortedDocuments((current) => {
            return current.filter((item) => item.id !== id);
        });
    }

    const toggleRemoveBackground = (documentId: string) => {
        setRemoveBackgroundDocumentIds((current) => {
            if (current.includes(documentId)) {
                return current.filter((item) => item !== documentId);
            } else {
                return [...current, documentId];
            }
        });
    }

    const moveSortedDocumentsRight = () => {
        if (!sortedDocuments) return;
        const selectedDocumentIndex = sortedDocuments.findIndex((item) => item.id === selectedDocumentId);
        if (selectedDocumentIndex === -1) return;
        const newSortedDocuments = [...sortedDocuments];
        const selectedDocument = newSortedDocuments.splice(selectedDocumentIndex, 1)[0];
        const newIndex = selectedDocumentIndex + 1;
        newSortedDocuments.splice(newIndex, 0, selectedDocument);
        setSortedDocuments(newSortedDocuments);
    }

    const moveSortedDocumentsLeft = () => {
        if (!sortedDocuments) return;
        const selectedDocumentIndex = sortedDocuments.findIndex((item) => item.id === selectedDocumentId);
        if (selectedDocumentIndex === -1) return;
        const newSortedDocuments = [...sortedDocuments];
        const selectedDocument = newSortedDocuments.splice(selectedDocumentIndex, 1)[0];
        const newIndex = selectedDocumentIndex - 1;
        newSortedDocuments.splice(newIndex, 0, selectedDocument);
        setSortedDocuments(newSortedDocuments);
    }


    const imageWidth = window.innerWidth / 2;

    const selectedDocument = selectedDocumentId ? sortedDocuments?.find((item: any) => item.id === selectedDocumentId) : null;
    const selectedDocumentImgUrl = selectedDocument ? `https://cokhhrrfza.cloudimg.io/_img_/${selectedDocument.processedFileKey}?width=${imageWidth}` : '';
    const selectedDocumentImgType = selectedDocumentImgUrl.toLowerCase().includes('.pdf') ? 'pdf' : 'image';

    const selectedDocumentFormData = documentsFormData?.find((doc) => doc.documentKey === sortedDocuments.find((d) => d.id === selectedDocumentId)?.documentKey);

    const idCardDocuments = sortedDocuments?.filter((d: any) => ['idCardComplete', 'idCardFront'].includes(d.label)) || [];

    console.log(sortedDocuments);

    return (
        <>
            <Box display={'flex'} flexDirection={"column"}>
                {
                    view === 'documents' && (
                        <Box height={'calc(100vh - 80px)'} display={'flex'}>
                            <Box flexGrow={1} display={'flex'} flexDirection={"column"} justifyContent={'center'} alignItems={'center'} flexBasis={'50%'}>
                                <Box pos={'relative'} background={'gray.600'} width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
                                    {selectedDocument ? (
                                        <Box>
                                            <CloseButton pos={'absolute'} top={5} left={5} onClick={() => onClickOnRemoveDocument(selectedDocument.id)} backgroundColor={'gray.800'} color={'white'} rounded={100} />
                                            <FormControl backgroundColor={'gray.800'} color={'white'} rounded={100} px={3} py={1} width={'auto'} pos={'absolute'} right={5} top={5} display='flex' alignItems='center'>
                                                <FormLabel htmlFor='background-removal' display={'flex'} justifyContent={'center'} alignItems={'center'} mb='0'>
                                                    <Box display={'flex'} me={2}><HelpTooltip>{helpTextDeleteBackground}</HelpTooltip></Box> Borrar fondo
                                                </FormLabel>
                                                <Switch colorScheme="green" isChecked={removeBackgroundDocumentIds.findIndex((id) => id === selectedDocument.id) !== -1} onChange={() => toggleRemoveBackground(selectedDocument.id)} id='background-removal' />
                                            </FormControl>
                                            <Button pos={'absolute'} bottom={5} left={5} onClick={() => moveSortedDocumentsLeft()} backgroundColor={'gray.800'} color={'white'} rounded={100}><ArrowBackIcon /></Button>
                                            <Button pos={'absolute'} bottom={5} right={5} onClick={() => moveSortedDocumentsRight()} backgroundColor={'gray.800'} color={'white'} rounded={100}><ArrowForwardIcon /></Button>
                                        </Box>
                                    ) : null}
                                    {
                                        selectedDocumentImgType === 'image' ? (
                                            <Image src={selectedDocumentImgUrl} alt={selectedDocument?.label} maxH={'100%'} />
                                        ) : <iframe src={selectedDocumentImgUrl} width="100%" height="100%"></iframe>
                                    }
                                </Box>
                            </Box>
                            <Box flexGrow={1} display={"flex"} flexWrap={"wrap"} flexBasis={'50%'}>
                                <Box display={"flex"} flexDirection={"column"} gap={'10px'} width={'100%'} padding={'20px'} overflowY={'auto'} height={'calc(100vh - 80px)'}>
                                    {selectedDocument?.data?.filter((dataItem: any) => !dataItem.hidden).map((dataItem: any) => (
                                        <FormControl key={dataItem.id}>
                                            <FormLabel>{dataItem.label}</FormLabel>
                                            <DocumentInput fieldId={dataItem.id} onChange={(value) => handleFormDataChange(selectedDocumentId, dataItem.id, value)} value={selectedDocumentFormData?.data[dataItem.id]} />
                                        </FormControl>
                                    ))}
                                    {selectedDocument?.data?.filter((dataItem: any) => !!dataItem.hidden).map((dataItem: any) => (
                                        <FormControl key={dataItem.id}>
                                            <FormLabel>{dataItem.label} (Sólo a modo informativo)</FormLabel>
                                            <DocumentInput disabled fieldId={dataItem.id} value={selectedDocumentFormData?.data[dataItem.id]} />
                                        </FormControl>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    )
                }
                {
                    /*view === 'validation' && (
                        <Box height={'calc(100vh - 80x)'} overflow={'auto'} padding={'20px'} flexGrow={1}>
                            <Heading marginBottom={'20px'} as='h2' size='xl'>
                                Validación
                            </Heading>
                            <Accordion allowMultiple defaultIndex={new Array(documents.length || 0).fill('').map((v, idx) => idx)}>
                                {documentValidationRules.map((validationRules) => {
                                    return (
                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton>
                                                    {validationRules.valid ? <CheckCircleIcon color={'green.500'} marginEnd={'10px'} /> : <WarningTwoIcon color={'orange.500'} marginEnd={'10px'} />}
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        <strong>{DocumentTypeLabels[validationRules.label] || 'Otro'}</strong>
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <ValidationResultsForDocument computedRules={validationRules.rules} />
                                            </AccordionPanel>
                                        </AccordionItem>
                                    )
                                })}
 
                            </Accordion>
                        </Box>
                    )*/
                }
                <Box backgroundColor={'gray.200'} height={'80px'} padding={'0 20px'} width={'100%'} gap={'10px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Box>
                        <Link to={'/'}><Button size="sm" leftIcon={<ArrowBackIcon />} colorScheme='gray.800' variant='outline'>Volver</Button></Link>
                        {/*<Button marginLeft={'10px'} onClick={() => setView(current => current === 'documents' ? 'validation' : 'documents')} size="sm" colorScheme='gray.800' variant='outline'>{view === 'validation' ? 'Documentos' : 'Validacion'}</Button>*/}
                    </Box>
                    <Box gap={'10px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        {
                            sortedDocuments?.map((item: any) => {
                                return (
                                    <Button height={'45px'} backgroundColor={selectedDocumentId === item.id ? 'gray.400' : 'gray.800'} color={'gray.50'} size="sm" onClick={() => setSelectedDocumentId(item.id)}>
                                        <VStack spacing='1' justifyContent={"space-between"}>
                                            <Text fontSize={'.8rem'}>{DocumentTypeLabels[item.label] || 'Otro'}</Text>
                                            <Text fontSize={'.7rem'}>{item.data?.find((itemData: any) => itemData.id === minimalDataPerLabel[item.label]?.[0])?.value}</Text>
                                        </VStack>
                                    </Button>
                                )
                            })
                        }
                    </Box>
                    <Box>
                        <Button size="sm" colorScheme='gray.800' variant='outline' onClick={() => setXMLModalOpen(true)} me={2}>Generar XML A9</Button>
                        <Button disabled size="sm" colorScheme='gray.800' variant='outline' onClick={() => !exportPDFIsLoading && exportPDF()} me={2}><Box display={'flex'} me={2}><HelpTooltip>{helpTextPdf}</HelpTooltip></Box>{exportPDFIsLoading ? <Spinner size={'sm'} me={2} /> : null} Exportar PDF</Button>
                        <Button size="sm" colorScheme='gray.800' variant='outline' onClick={() => mutatePersistDocuments()}><Box display={'flex'} me={2}><HelpTooltip>{helpTextSave}</HelpTooltip></Box>  Guardar</Button>
                    </Box>
                </Box>
            </Box>
            <Modal onClose={() => setXMLModalOpen(false)} size={'xl'} isOpen={XMLModalOpen}>
                <ModalOverlay />
                <ModalContent p={5}>
                    <ModalHeader>Generar XML A9</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text mb={5}>Selecciona quién es el comprador y el vendedor. Todos los campos son requeridos.</Text>
                        <FormControl>
                            <FormLabel>Comprador</FormLabel>
                            <Select value={buyerDocumentKey} onChange={(e) => setBuyerDocumentKey(e.target.value)} placeholder='Seleccionar comprador'>
                                {idCardDocuments.filter((d: any) => ['idCardComplete', 'idCardFront'].includes(d.label)).map((doc: any) => (
                                    <option value={doc.documentKey}>{doc.data?.find((d: any) => d.id === 'documentNumber').value}</option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl mt={5}>
                            <FormLabel>Vendedor</FormLabel>
                            <Select value={sellerDocumentKey} onChange={(e) => setSellerDocumentKey(e.target.value)} placeholder='Seleccionar vendedor'>
                                {idCardDocuments.filter((d: any) => ['idCardComplete', 'idCardFront'].includes(d.label)).map((doc: any) => (
                                    <option value={doc.documentKey}>{doc.data?.find((d: any) => d.id === 'documentNumber').value}</option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl mt={5}>
                            <FormLabel>Vehículo</FormLabel>
                            <FormHelperText mb={2}>
                                Se usará el permiso de circulación del primer vehículo encontrado
                            </FormHelperText>
                            <Input disabled value={selectedVehicleCirculationPermit?.data?.find((d: any) => d.id === 'vehicleRegistrationNumber')?.value || 'Permiso de circulación no encontrado o sin matrícula'} />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => setXMLModalOpen(false)}>Cerrar</Button>
                        <Button disabled={!canGenerateXML} colorScheme="blue" ms={3} onClick={() => { onClickOnGenerateXML() }}>Generar</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

/*const documentValidationRules: { label: string, rules: ComputedRules[], valid: boolean }[] = documents ? [
        {
            label: 'idCard',
            rules: rules['idCard']?.map((rule: any) => ({
                description: rule.description,
                valid: rule.isValid(documents),
                values: rule.values(documents)
            })),
            valid: rules['idCard']?.map((rule: any) => rule.isValid(documents)).every((v: boolean) => v)
        },
        ...documents?.filter((d: any) => ['idCard', 'circulationPermitV2Document', 'contract', 'mandate', 'vehicleTechnicalDocumentV2'].includes(d.label)).map((item: any) => {
            return {
                label: item.label,
                rules: rules[item.label]?.map((rule: any) => ({
                    description: rule.description,
                    valid: rule.isValid(documents),
                    values: rule.values(documents)
                })),
                valid: rules[item.label]?.map((rule: any) => rule.isValid(documents)).every((v: boolean) => v)
            }
        }) || []
    ].sort((a, b) => a.valid === b.valid ? 0 : a.valid ? 1 : -1) : [];*/
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Tooltip, TooltipProps } from "@chakra-ui/react";

interface Props extends TooltipProps {
}

export const HelpTooltip = (props: Props) => {
    return (
        <Tooltip bg={'teal'} hasArrow label={props.children} {...props}>
            <InfoOutlineIcon fontSize={'.8rem'}/>
        </Tooltip>
    )
}
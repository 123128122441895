import { config } from "./config";

export class ApiService {
    private static getAuthHeaders() {
        const token = window.localStorage.getItem('token');
        return {
            'Authorization': `Bearer ${token}`
        }
    }

    private static onUnauthorized() {
        localStorage.removeItem('token');
        window.location.href = '/#/login';
    }

    static customFetch = async (url: string, options?: RequestInit) => {
        try {
            const authHeaders = ApiService.getAuthHeaders();
            options = {
                ...options,
                headers: {
                    ...options?.headers,
                    ...authHeaders
                }
            };
            const response = await fetch(url, options);
            if (response.status === 401) {
                ApiService.onUnauthorized();
            }
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    static login(data: { email: string, password: string }) {
        return ApiService.customFetch(`${config.apiUrl}/auth/login`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: data.email,
              password: data.password,
            }),
          })
    }

    static signUp(data: { email: string, password: string }) {
        return ApiService.customFetch(`${config.apiUrl}/auth/signup`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: data.email,
              password: data.password,
            }),
          })
    }

    static email() {
        return ApiService.customFetch(`${config.apiUrl}/auth/getuserdata`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
    }

    static uploadFiles = async (formData: FormData) => {
        return ApiService.customFetch(`${config.apiUrl}/documents`, {
            method: 'POST',
            body: formData,
        })
    }

    static persistDocumentsUserData = (documents: {documentKey: string, data: Record<string, any>}[]) => {
        return ApiService.customFetch(`${config.apiUrl}/persist-documents-user-data`, {
            method: 'POST',
            body: JSON.stringify(documents),
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }

    static generateA9XML = (data: {documentKeys: string[], buyerDocumentKey?: string, sellerDocumentKey?: string}) => {
        return ApiService.customFetch(`${config.apiUrl}/generate-xml`, {
            method: 'POST',
            body: JSON.stringify({
                documentKeys: data.documentKeys,
                buyerDocumentKey: data.buyerDocumentKey,
                sellerDocumentKey: data.sellerDocumentKey
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }

    static exportPDF = (documents: {file_key: string, removeBackground: boolean}[]): Promise<string> => {
        return fetch(`https://szje9d2mo3.execute-api.eu-west-3.amazonaws.com/Prod/document-merging`, {
            method: 'POST',
            body: JSON.stringify({files: documents}),
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then(res => JSON.parse(res.body).merged_file_key)
    }

    static getAllDocuments = (isShowingProcessed: boolean) => ApiService.customFetch(isShowingProcessed ? `${config.apiUrl}/documents/processed` : `${config.apiUrl}/documents`);

    static getSomeDocuments = (documentIds?: string[]) => ApiService.customFetch(`${config.apiUrl}/documents/some?documentIds=${documentIds?.join(',') || ''}`);

}
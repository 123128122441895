import { Input, Select } from "@chakra-ui/react"
import { getInputTypeFromDataItemId } from "./utils"

interface Props {
    fieldId: string;
    value: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
}

export const DocumentInput = (
    { fieldId, value, onChange, disabled }: Props
) => {
    if (fieldId === 'gender') return (
        <Select onChange={(e) => onChange?.(e.target.value)} value={value}>
            <option value='M'>Masculino</option>
            <option value='F'>Femenino</option>
        </Select>
    )
    return (
        <Input disabled={disabled} type={getInputTypeFromDataItemId(fieldId)} onChange={(e) => onChange?.(e.target.value)} value={value}></Input>
    )
}
import { Box, Button, Flex, HStack, Heading, SimpleGrid, Tag, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { Header } from "./Header";
import useSessionStorageState from "./useSessionStorageState";
import { format, parse } from "date-fns";
import { ApiService } from "./ApiService";
import { TablePageDocument } from "./TablePageDocument";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

export const TablePage = () => {

    const [selection, setSelection] = useSessionStorageState<string[]>('selection', []);
    const [isShowingProcessed, setIsShowingProcessed] = useState<boolean>(false);

    const toggleDocumentSelection = (id: string) => {
        setSelection(selection.find(item => item === id) ? [...selection.filter(item => item !== id)] : [...selection, id])
    }

    const { isLoading, error, data, refetch } = useQuery('allDocuments', () => {
        return ApiService.getAllDocuments(isShowingProcessed).then(res => res.json());
    }, {
        refetchInterval: 10000,
    })

    const navigate = useNavigate();

    /*const selectedDocuments = useMemo(() => {
        const docs = data?.map(({ documents }: any) => documents.map((d: any) => d.documents)).flat(2).filter(({ id }: any) => selection.find(selectionItem => selectionItem === id));
        return selection.map(id => docs.find((doc: any) => doc.id === id)).filter(Boolean);
    }, [selection, data])*/

    useEffect(() => {
        refetch();
    }, [isShowingProcessed])


    if (isLoading || !data) return <>Loading...</>
    if (error) return <>An error has occurred: {error}</>

    return (
        <>
            <Header setIsShowingProcessed={(value) => setIsShowingProcessed(value)} isShowingProcessed={isShowingProcessed} documentSelection={selection} onClearDocumentSelection={() => setSelection([])} />
            {/*<Box backgroundColor={'blue.200'} height={'60px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} px={5}>
                <Box minW={0}>
                    <HStack maxWidth={'100%'} overflow={'scroll'}>
                        {selectedDocuments?.map((item: any) => (
                            <TablePageDocument isSmall onClickOnRemove={() => toggleDocumentSelection(item.id)} hideImage item={item}></TablePageDocument>
                        ))}
                    </HStack>
                </Box>
                <Box minW={'156px'}>
                    <Button onClick={() => setSelection([])} size="sm" me={2} colorScheme='blue'>Borrar</Button>
                    <Button disabled={!selection.length} onClick={() => selection.length && navigate('/tramitar')} size="sm" colorScheme={'blue'}>Tramitar</Button>
                </Box>
                    </Box>*/}
            {
                !data || !data.length ? <Box height='calc(100vh - 120px)' display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <VStack>
                        <Heading size='md'>Todavía no hay documentos</Heading>
                        <Text>Los documentos que subas pueden tardar hasta 1 minuto en aparecer aquí</Text>
                    </VStack>

                </Box> : null
            }
            <Box height='calc(100vh - 120px)' overflow={'scroll'}>
                {data?.map(({ groupLabels, documents }: any) => (
                    <Box p={5}>
                        <Heading as='h6' size='xs'>
                            <Tag colorScheme={'purple'}>{groupLabels.date ? format(parse(groupLabels.date, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : 'Sin fecha'}</Tag>
                        </Heading>
                        <SimpleGrid columns={4} mt={2} spacing={2}>
                            {documents?.filter(({ groupLabels, documents }: any) => Object.values(groupLabels).some(v => !!v)).map(({ groupLabels, documents }: any) => (
                                <Box pt={5}>
                                    <Heading as='h6' size='xs' mb={2}>
                                        <GroupLabels groupLabels={groupLabels} />
                                    </Heading>
                                    <SimpleGrid columns={2} mt={2} spacing={2}>
                                        {documents?.map((item: any) => (
                                            <TablePageDocument item={item} isSelected={!!selection.find(selectionItem => selectionItem === item.id)} onToggleSelect={(id) => toggleDocumentSelection(id)}></TablePageDocument>
                                        ))}
                                    </SimpleGrid>
                                </Box>
                            ))}
                        </SimpleGrid>
                        <Heading as='h6' size='xs' my={5}>Documentos sin clasificar</Heading>
                        <SimpleGrid columns={6} mt={2} spacing={2}>
                            {documents?.filter(({ groupLabels, documents }: any) => !Object.values(groupLabels).some(v => !!v)).map(({ groupLabels, documents }: any) => (
                                documents?.map((item: any) => (
                                    <TablePageDocument item={item} isSelected={!!selection.find(selectionItem => selectionItem === item.id)} onToggleSelect={(id) => toggleDocumentSelection(id)}></TablePageDocument>
                                ))
                            ))}
                        </SimpleGrid>
                    </Box >
                ))}
            </Box>
        </>
    );
}

const colorSchemeForKey: Record<string, string> = {
    'fullName': 'blue',
    'date': 'purple',
    'idNumber': 'green',
}

const GroupLabels = ({ groupLabels }: { groupLabels: Record<string, string> }) => (
    <Flex wrap={'wrap'}>
        {Object.entries(groupLabels).filter(([key, value]) => !!value).map(([key, value]) => (
            <Tag mb={2} mr={2} colorScheme={colorSchemeForKey[key]}>{value.toLocaleUpperCase() || "Sin clasificar"}</Tag>
        ))}
    </Flex>
)
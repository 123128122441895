import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  useColorModeValue,
  useToast,
  HStack,
  Text,
  List,
  ListIcon,
  ListItem,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { AuthService } from './AuthService';
import { useNavigate } from 'react-router-dom';
import { ApiService } from './ApiService';
import { CheckIcon } from '@chakra-ui/icons';

export default function SignUp() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');
  const toast = useToast();
  const navigate = useNavigate();

  const { mutate: register } = useMutation((data: { email: string, password: string, repeatedPassword: string }) => {
    // Check if passwords are equal
    if (data.password !== data.repeatedPassword) {
      toast({
        title: "Las contraseñas no coinciden",
        status: "error",
        duration: 9000,
        isClosable: true,
      })
      // We avoid doing a request by resolving the promise
      return Promise.resolve()
    }

    return ApiService.signUp({ email: data.email, password: data.password }).then(async res => {
      switch (res.status) {
        // TODO No he trobat els casos en que s'envia cada codi de resposta
        case 401: toast({
          title: "El usuario no se ha podido registrar",
          status: "error",
          duration: 9000,
          isClosable: true,
        }); break;

        case 409: toast({
          title: (await res.json()).message,
          status: "error",
          duration: 9000,
          isClosable: true,
        }); break;

        case 201:
          toast({
            title: "Usuario registrado correctamente",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          // After sign up, log in

          AuthService.onLogin((await res.json()).access_token);
          setTimeout(() => navigate('/'), 1000);
          break;
      }
    })
  })

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} minW={'lg'} maxW={'xxl'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Wheelz</Heading>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
        >
          <HStack>
            <Stack w={'65%'} p={8} spacing={4}>
              <Heading size={'xl'}>Crea tu cuenta de prueba</Heading>
              <List spacing={3}>
                <ListItem>
                  <ListIcon as={CheckIcon} color='gray' />
                  Puedes subir y tramitar hasta 20 documentos
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckIcon} color='gray' />
                  Soportamos DNI, Permisos de Circulación, Ficha Técnica, Contrato y Mandato
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckIcon} color='gray' />
                  Corrige fácilmente si hay errores de lectura
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckIcon} color='gray' />
                  Exporta los datos a la plataforma A9
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckIcon} color='gray' />
                  Puedes exportar los documentos en PDF e incluso borrar su fondo
                </ListItem>
              </List>
            </Stack>
            <Stack w={'35%'} p={8} spacing={4}>
              <FormControl id="email">
                <FormLabel>Email</FormLabel>
                <Input value={email} onChange={(e) => setEmail(e.target.value)} type="email" />
              </FormControl>
              <FormControl id="password">
                <FormLabel>Contraseña</FormLabel>
                <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
              </FormControl>
              <FormControl id="password">
                <FormLabel>Repetir Contraseña</FormLabel>
                <Input type="password" value={repeatedPassword} onChange={(e) => setRepeatedPassword(e.target.value)} />
              </FormControl>
              <Stack spacing={10}>
                <Button
                  onClick={() => register({ email, password, repeatedPassword })}
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}>
                  Registrarme
                </Button>
              </Stack>
            </Stack>
          </HStack>
        </Box>
      </Stack>
    </Flex>
  )
}